<template>
  <div>
    <v-card class="login__container mx-auto">
      <v-card-text>
        <v-alert
          dense
          prominent
          shaped
          v-if="somethingWentWrong"
          type="warning"
        >{{ $t('somethingWentWrong') }}
        </v-alert>
        <div class="container text-center mt-2">
          <img class="logo" :src="logoSvg" :alt="logoAlt" />
        </div>
        <h1 v-if="!afterLogout && !afterAutologout" class="login__info-text ma-6 ma-lg-16 mt-lg-8" v-html="$t('login_info')"></h1>
        <h1 v-if="afterLogout" class="login__info-text ma-6 ma-lg-16 mt-lg-8" v-html="$t('logoutInfo')"></h1>
        <h1 v-if="afterAutologout" class="login__info-text ma-6 ma-lg-16 mt-lg-8" v-html="$t('logoutInfo')"></h1>
        <form class="ma-6 ma-lg-16 mt-lg-8" @submit.prevent="submitLogin">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                :label="$t('client_number')"
                class="login__input"
                outlined
                required
                autofocus
                v-model="login"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                :label="$t('your_password')"
                class="login__input"
                outlined
                required
                type="password"
                v-model="password"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="text-right grey-link mt-0 pt-0">
              <router-link :to="{name: 'remindPassword'}">{{ $t('remind_password') }}</router-link>
            </v-col>
            <v-col cols="12" sm="12">
              <p v-if="blockedAccount" class="v-messages error--text">{{ $t('blockedAccount') }}</p>
              <p v-if="wrongCredentials" class="v-messages error--text">{{ $t('wrongCredentials') }}</p>
            </v-col>
            <v-col cols="12" lg="12" class="text-center">
              <button
                type="submit"
                class="main-button main-button-fixed"
              >{{ $t('login_button') }}</button>
            </v-col>
          </v-row>
        </form>
        <div class="text-center" v-if="mobileUser || desktopUserNonBG">
          <div>{{ $t('dont_have_account') }}</div>
          <router-link :to="'sign_up'">
            <button class="main-button-white-small main-button-fixed d-xs-inline mt-0">
              <span>{{ $t('registration') }}</span>
              <v-icon
                  small
                  class="ml-2 arrow-icon arrow-gradient"
                >
                  fas fa-arrow-right
                </v-icon>
            </button>
          </router-link>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AppStorage, getIsExpoApp } from 'cuf-expo-bridge'

export default {
  name: 'Login',
  data () {
    return {
      login: '',
      password: '',
      error: false,
      blockedAccount: false,
      wrongCredentials: false
    }
  },
  computed: {
    ...mapGetters({ currentClient: 'currentClient' }),

    afterLogout () {
      return this.$route.query.type === 'logout'
    },
    afterAutologout () {
      return this.$route.query.type === 'autologout'
    },
    somethingWentWrong () {
      return this.$route.query.type === 'somethingWentWrong'
    },

    mobileUser () {
      return getIsExpoApp()
    },

    desktopUserNonBG () {
      return !getIsExpoApp() && (this.$i18n.locale !== 'bg' && !location.host.includes('.bg'))
    },

    logoSvg () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return require('@/assets/euro-tax.ro.svg')
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return require('@/assets/euro-tax.bg.svg')
      } else if (location.host.includes('.euro-tax.pl')) {
        if (this.$i18n.locale === 'ro') {
          return require('@/assets/euro-tax.ro.svg')
        } else if (this.$i18n.locale === 'bg') {
          return require('@/assets/euro-tax.bg.svg')
        } else {
          return require('@/assets/euro-tax.pl.svg')
        }
      } else {
        return require('@/assets/cuf-logo.svg')
      }
    },
    logoAlt () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return ''
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return ''
      } else if (location.host.includes('.euro-tax.pl')) {
        return 'Panel Klienta Euro-tax”'
      } else {
        return 'Panel Klienta CUF'
      }
    }
  },
  created () {
    this.checkCurrentLogin()

    if (this.$route.query.login) {
      this.login = this.$route.query.login
    }
  },
  updated () {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin () {
      if (this.currentClient) {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          this.$router.replace(this.$route.query.redirect || '/dashboard')
        } else {
          this.$router.replace(this.$route.query.redirect || '/documents')
        }
      }
    },
    submitLogin () {
      this.blockedAccount = false
      this.wrongCredentials = false
      this.$http.post('/auth/', { login: this.login, password: this.password })
        .then(response => this.loginSuccessful(response))
        .catch((error) => {
          this.blockedAccount = error.request.response.includes('"blocked_account":true')
          this.wrongCredentials = error.request.response.includes('invalid_credentials')
        })
    },
    loginSuccessful (req) {
      if (!req.data.token) {
        this.loginFailed()
        return
      }
      this.error = false
      // const obj = JwtDecode(req.data.token)

      AppStorage.set('token', req.data.token)
        .then(() => this.$store.dispatch('login', req.data.token))
        .then(response => {
          // this.$store.dispatch('changeLocale', obj.locale)
          this.$store.dispatch('getMessages')
          this.$store.dispatch('getInfo')
          // this.$i18n.locale = obj.locale
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.$router.replace(this.$route.query.redirect || '/dashboard')
          } else {
            this.$router.replace(this.$route.query.redirect || '/documents')
          }
        })
    },
    loginFailed () {
      this.$store.dispatch('logout')
      AppStorage.remove('token')
      this.$swal({
        toast: false,
        position: 'center',
        showConfirmButton: false,
        timer: 1000,

        type: 'error',
        text: 'Login failed!'
      })
    }
  }
}
</script>

<style lang="scss">
  .login__container {
    width: 574px;
    min-height: 586px;
    border-radius: 20px !important;
    opacity: 1;
    background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, #F57921, #D20F6E) border-box !important;
    border: 2px solid transparent !important;
  }

  .login__info-text {
    line-height: 1.55;
    text-align: left;
  }

  .login__input {
    fieldset {
      color: transparent !important;
    }

    .v-input__slot {
      background-color: rgba(180, 180, 180, 0.1) !important;
      border-radius: 10px;
      border: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px rgb(247 247 247) inset !important;
  }

  .arrow-gradient {
    background: linear-gradient(90deg, #F57921 0.05%, #D2116E 76.54%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
</style>
